import React, { useEffect } from "react";
import EULAContent from "../../data/EULAContent";
import parse from "html-react-parser";
import useScreenSize from "../../hooks/useScreenSize";

const Eula = () => {
  const { isMobile } = useScreenSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={isMobile ? "" : "content"}>
      {parse(EULAContent)}
    </div>
  );
};

export default Eula;
