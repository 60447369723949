import React, { useState } from "react";
import useScreenSize from "../../hooks/useScreenSize";
import Carousel from "./Carousel/Carousel";

import "./TutorialContainer.css";

const tutorialCopy = [
  {
    title: "Dive into the UI 🌊",
    subTitle: "Effortlessly manage captions with our sleek, user-friendly interface!",
  },
  {
    title: "Ignite the Spark ⚡",
    subTitle: "Craft captivating captions using your chosen prompts!",
  },
  {
    title: "Make It Personal 🔥",
    subTitle: "Customize captions with flair using a variety of options.",
  },
  {
    title: "Share and Edit with Ease 🤩",
    subTitle: "Manage, share, and edit captions seamlessly to suit your needs!",
  },
  {
    title: "Copy, Save, Repeat 🔄",
    subTitle: "Quickly copy or save captions to your folder for effortless organization!",
  },
  {
    title: "Edit to Your Heart's Content 💖",
    subTitle: "Fine-tune your captions to make them truly yours!",
  },
  {
    title: "Stay Organized 🗂️",
    subTitle: "Manage captions easily by creating folders and sorting them!",
  },
];

const TutorialContainer = () => {
  const { isMobile } = useScreenSize();
  const [activeIndex, setActiveIndex] = useState<number>(0);

  return (
    <div className="flex centered column">
      <span className={isMobile ? "fs24 semibold" : "fs40 semibold"}>
        Craft professional-quality captions in seconds ⚡
      </span>
      <p className={isMobile ? "regular lh24" : "fs18 regular lh24"}>
        Make your captions yours with various combinations of tones, lengths,
        emojis and hashtags.
      </p>

      <div className="carousel-container">
        <Carousel
          onSliderChange={(index) => setActiveIndex(index)}
          altText={`${tutorialCopy[activeIndex].title} - ${tutorialCopy[activeIndex].subTitle}`}
        />
      </div>

      <div className="centered column flex">
        <p
          className={
            isMobile ? "semibold carousel-title-m" : "carousel-title semibold"
          }
        >
          {tutorialCopy[activeIndex].title}
        </p>
        <p
          className={
            isMobile ? "regular lh24" : "carousel-subtitle regular lh24"
          }
        >
          {tutorialCopy[activeIndex].subTitle}
        </p>
      </div>
    </div>
  );
};

export default TutorialContainer;
